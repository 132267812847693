<template>
  <router-view></router-view>
</template>

<script>
  import filter from 'lodash/filter';
  import find from 'lodash/find';

  export default {
    name: 'judge-wrapper',
    components: {
    },
    data: function() {
      return {
        page: 'idle',
        judge: undefined
      }
    },
    computed: {
      exercise: function() {
        return this.$store.state.exercise.exercise;
      },
      timer: function() {
        return this.$store.state.exercise.timer;
      },
      activePass: function() {
        return this.$store.state.exercise.pass;
      },
      event: function(){
        return find(require('client/store').state.events.items, (item) => {
          return item.id === this.$route.params.eventId;
        });
      },
      panel: function () {
        return find(this.$store.state.panels.items, item => { return item.id === this.$route.params.panelId });
      },
      judges: function () {
        return filter(this.$store.state.members.items, item => { return item.judge });
      },
      session: function() {
        const panel = find(this.$store.state.panels.items, item => { return item.id === this.$route.params.panelId });
        return find(this.$store.state.sessions.items, item => { return item.id === panel.sessionId });
      },
    },
    mounted: function() {
      const member = find(this.panel.members, item => { return item.id === this.$route.params.panelMemberId });
      if (member) {
        this.judge = find(this.judges, item => { return item.id === member.judgeId });
      }

      this.setState();
    },
    watch: {
      panel: function() {
        this.setState();
      },
      exercise: function() {
        this.setState();
      }
    },
    methods: {
      setState: function() {

        if (! this.panel.active) {
          this.$navigation.goto({name: 'judge.panels'});
          return;
        }

        if (! this.panel.exerciseId) {
          this.page = 'idle';
          this.$navigation.goto({name: 'judge.judge.idle'});
          return;
        }

        let switchPass = false;
        if (this.activePass !== this.panel.activePass) {
          switchPass = true;
          this.$store.dispatch("exercise.setPass", this.panel.activePass);
        }

        if (this.page === 'idle' || switchPass) {
          this.page = 'score';
          this.$navigation.goto({
            name: 'judge.judge.exercise',
            params: {exerciseId: this.panel.exerciseId, pass: this.panel.activePass}
          });
        }
      }
    }
  };
</script>

<style scoped>
.judge-panel {
  width: 100%;
  padding: 0 6px;

}
</style>
