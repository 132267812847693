<template>
  <div class="c-button--number-pad">
    <button v-on:click.prevent="valuePressed(7)"
            class="c-button c-button--square c-button--primary">
      7
    </button>
    <button v-on:click.prevent="valuePressed(8)"
            class="c-button c-button--square c-button--primary">
      8
    </button>
    <button v-on:click.prevent="valuePressed(9)"
            class="c-button c-button--square c-button--primary">
      9
    </button>

    <button v-on:click.prevent="valuePressed(4)"
            class="c-button c-button--square c-button--primary">
      4
    </button>
    <button v-on:click.prevent="valuePressed(5)"
            class="c-button c-button--square c-button--primary">
      5
    </button>
    <button v-on:click.prevent="valuePressed(6)"
            class="c-button c-button--square c-button--primary">
      6
    </button>

    <button v-on:click.prevent="valuePressed(1)"
            class="c-button c-button--square c-button--primary">
      1
    </button>
    <button v-on:click.prevent="valuePressed(2)"
            class="c-button c-button--square c-button--primary">
      2
    </button>
    <button v-on:click.prevent="valuePressed(3)"
            class="c-button c-button--square c-button--primary">
      3
    </button>

    <button v-on:click.prevent="actionPressed('dot')"
            class="c-button c-button--square c-button--primary"
            :disabled="valueType.precision===0">
      {{$t('.')}}
    </button>
    <button v-on:click.prevent="valuePressed(0)"
            class="c-button c-button--square c-button--primary">
      0
    </button>
    <button v-on:click.prevent="actionPressed('back')"
            class="c-button c-button--square c-button--primary">
      {{$t('C')}}
    </button>
  </div>
</template>

<script>
  export default {
    name: "numpad",
    props: ["valueType"],
    methods: {
      valuePressed: function (value) {
        this.$emit('value', {value: value});
      },
      actionPressed: function (action) {
        this.$emit('action', {action: action});
      },
      keyPressed: function () {
        return false;
      }
    }
  };
</script>
