<template>
    <div>
        <panelMember :panelMemberId="$route.params.panelMemberId" @submit="update($event)" @cancel="cancel($event)"
                     @clear="clear($event)"/>
    </div>
</template>

<script>
  import find from 'lodash/find';
  import includes from 'lodash/includes';

  export default {
    name: 'panelMember-confirm',
    components: {
      'panelMember': require('./member-config.vue').default,
    },
    data: function () {
      return {
        panelType: undefined
      }
    },
    computed: {
      event: function () {
        return this.$store.state.events.current
      },
      panel: function () {
        return find(this.$store.state.panels.items, item => { return item.id === this.$route.params.panelId })
      }
    },
    mounted: function () {
      this.panelType = find(this.$store.state.eventDiscipline.panelTypes, item => { return item.id === this.panel.panelTypeId })
    },
    methods: {
      update: function (panelMember) {
        const opts = {
          panelId: panelMember.panelId,
          data: panelMember
        }
        this.$store.dispatch('panel.member.save', opts).then(() => {
          const chairPosition = this.panelType.chairPosition
          if (includes(panelMember.positions, chairPosition)) {
            this.$navigation.goto({name: 'judge.chair.rotations', params: {panelMemberId: panelMember.id}})
          }
          else {
            this.$navigation.goto({name: 'judge.judge.idle', params: {panelMemberId: panelMember.id}})
          }
        })

      },
      cancel: function () {
        this.$navigation.goto({name: 'judge.panel.members'});
      },
      clear: function(event) {
        this.$store.dispatch('panel.member.clear', event).then(() => {
          this.$navigation.goto({name: 'judge.panel.members'});
        });
      }
    }
  }
</script>

<style scoped>

</style>
