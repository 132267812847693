<template>
  <div class="c-breadcrumb u-3-of-5-at-medium">
    <router-link exact :to="{ name: 'judge.panels'}" class="c-breadcrumb__item c-breadcrumb__link">
      {{$t('judge.console')}}: {{$t('home')}}
    </router-link>
    <template v-if="session">
      <template v-if="!judge">
        <span class="c-breadcrumb__item">{{eventDisciplineTitle()}} {{session.name}} {{panel.name}}</span>
      </template>
      <template v-else>
        <router-link exact :to="{ name: 'judge.panel.members'}" class="c-breadcrumb__item c-breadcrumb__link">
          {{eventDisciplineTitle()}} {{session.name}} {{panel.name}}
        </router-link>
        <span v-if="judge" class="c-breadcrumb__item">{{judge.lastName}} {{judge.firstname}}</span>
      </template>
    </template>
  </div>
</template>

<script>
  import panelHandler from 'client/lib/panelHandler.js';

  import filter from 'lodash/filter';
  import find from 'lodash/find';

  export default {
    name: 'breadcrump',
    data: function() {
      return {
        eventDiscipline: null,
        session: null,
        judge: null,
      };
    },
    computed: {
      event: function () {
        return this.store.state.events.current
      },
      panel: function () {
        return find(this.$store.state.panels.items, item => {
          return item.id === this.$route.params.panelId;
        });
      },
      judges: function () {
        return filter(this.$store.state.members.items, item => { return item.judge });
      },
    },
    watch: {
      '$route.params': function() {
        this.init();
      }
    },
    mounted: function() {
      this.init();
    },
    methods: {
      init: function() {
        if (! this.panel) {
          return;
        }

        this.eventDiscipline = find(this.$store.state.eventDisciplines.items, item => {
          return item.id === this.panel.eventDisciplineId;
        });
        this.session = find(this.$store.state.sessions.items, item => { return item.id === this.panel.sessionId });
        if (this.$route.params.panelMemberId) {
          const member = find(this.panel.members, item => { return item.id === this.$route.params.panelMemberId });
          if (member) {
            this.judge = find(this.judges, item => { return item.id === member.judgeId });
          }
        } else {
          this.judge = null;
        }
      },
      eventDisciplineTitle: function() {
        if (! this.eventDiscipline) {
          return '';
        }
        return this.eventDiscipline.shortName ? this.eventDiscipline.shortName : this.eventDiscipline.name;
      },
      getPanelName: function(panel) {
        return panelHandler.panelName(panel);
      },
    },
  }
</script>

<style scoped>
</style>
