<template>
<div class="timers">
  <h4>{{$t('timer.wait')}}</h4>
  <div class="exerciseTimerGroup">
    <div class="timerButtons" v-if="timer">
      <div class="c-button c-button--large c-button--primary"
           :class="{'c-button--disabled': exerciseTime.status !== 'wait'}"
           v-on:click="buttonGreenLight()">
        {{$t('timer.greenLight')}}
      </div>
      <div class="c-button c-button--large c-button--primary"
           :class="{'c-button--disabled': exerciseTime.status !== 'start'}"
           v-on:click="buttonExerciseReady()">
        {{$t('timer.ready')}}
      </div>
    </div>
    <div class="exerciseTimer" :style="'color: ' + timers.before.color">
      {{formatTime(timers.before.time)}}
    </div>
  </div>

  <h4>{{$t('timer.exercise')}}</h4>
  <div class="exerciseTimerGroup">
    <div class="timerButtons" v-if="timer">
      <div class="c-button c-button--large c-button--primary"
           :class="{'c-button--disabled': exerciseTime.status !== 'start' && exerciseTime.status !== 'ready'}"
           v-on:click="buttonExerciseStart()">
        {{$t('timer.exerciseStart')}}
      </div>
      <div class="c-button c-button--large c-button--primary"
           :class="{'c-button--disabled': exerciseTime.status !== 'running'}"
           v-on:click="buttonExerciseFinish()">
        {{$t('timer.exerciseFinish')}}
      </div>
    </div>
    <div class="exerciseTimer" :style="'color: ' + timers.exercise.color">
      {{formatTime(timers.exercise.time)}}
    </div>
  </div>

  <template v-if="fallEnabled">
    <h4>{{$t('timer.fall')}}</h4>
    <div class="exerciseTimerGroup">
      <div class="timerButtons" v-if="fallTimer">
        <div class="c-button c-button--large c-button--primary"
             :class="{'c-button--disabled': !fallEnabled || exerciseTime.status !== 'running'}"
             v-on:click="buttonFall()">
          {{$t('timer.exerciseFall')}}
        </div>
        <div class="c-button c-button--large c-button--primary"
             :class="{'c-button--disabled': exerciseTime.status !== 'fallWait'}"
             v-on:click="buttonFallTimeStart()">
          {{$t('timer.exerciseFallStart')}}
        </div>
        <div class="c-button c-button--large c-button--primary"
             :class="{'c-button--disabled': exerciseTime.status !== 'fall'}"
             v-on:click="buttonFallTimeEnd()">
          {{$t('timer.exerciseFallEnd')}}
        </div>
        <div class="c-button c-button--large c-button--primary"
             :class="{'c-button--disabled': exerciseTime.status !== 'fallEnd' && exerciseTime.status !== 'fall'}"
             v-on:click="buttonFallRestart()">
          {{$t('timer.exerciseFallRestart')}}
        </div>
      </div>
      <div class="exerciseTimer">
        <ol v-if="timers.currentFall !== null || timers.falls.length">
          <li v-for="fall in timers.falls" :key="fall.time" :style="'color: ' + fall.color">
            {{formatTime(fall.time)}}
          </li>
          <li v-if="timers.currentFall !== null" :style="'color: ' + timers.currentFall.color">
            {{formatTime(timers.currentFall.time)}}
          </li>
        </ol>
        <span v-else>{{$t('timer.nofalls')}}</span>
      </div>
    </div>
  </template>
  <div class="c-actions">
    <div class="c-actions__buttons">
      <button v-on:click.prevent="previous()"
              class="c-button c-button--primary"
              :class="{'c-button--disabled': true}">
        &lt; {{$t('panel.previous')}}
      </button>
    </div>

    <div class="c-actions__buttons">
      <button v-on:click.prevent="buttonReset()"
              class="c-button c-button--secondary">
        {{$t('timer.reset')}}
      </button>
      <button v-on:click.prevent="next()"
              class="c-button c-button--primary">
        {{$t('panel.next')}} >
      </button>
    </div>
  </div>
</div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep';
  import find from 'lodash/find';
  import forEach from 'lodash/forEach';
  import map from 'lodash/map';

  import numeral from 'numeral';
  const timesync = require('timesync');

  export default {
    name: "exerciseTime",
    data: function() {
      return {
        intervalTimer: null,
        beep: undefined,
        ts: null,
        fallEnabled: false,
        timers: {
          before: {
            time: 0,
            color: '#000000',
          },
          exercise: {
            time: 0,
            color: '#000000'
          },
          falls: [],
          currentFall: null
        },
        exerciseTime: {
          status: 'wait',
          before: {
            startTime: null,
            endTime: null
          },
          exercise: {
            startTime: null,
            endTime: null,
          },
          currentFall: {},
          falls: [],
          totalFallTime: 0,
        }
      };
    },
    computed: {
      exercise: function () {
        return this.$store.state.exercise.exercise;
      },
      activePass: function () {
        return this.$store.state.exercise.pass;
      },
      exerciseType: function() {
        return this.$store.state.exercise.exerciseType;
      },
      timer: function() {
        return this.$store.state.exercise.timer;
      },
      fallTimer: function() {
        return this.$store.state.exercise.fallTimer;
      }
    },
    watch: {
      exercise: function() {
        this.copyTime();
      }
    },
    created: function () {
      this.ts = timesync.create({
        server: '/timesync',
        interval: 120000,
      });

      this.beep = new Audio('/images/beep.wav');
      this.fallEnabled = this.exerciseType.fallTimer !== undefined;

      this.copyTime();

      if (this.timer) {
        this.saveTimeInformation();
      }

      let newTime = 0;
      let color = '#0000';
      this.intervalTimer = setInterval(() => {
        const now = this.ts.now();
        switch(this.exerciseTime.status) {
          case 'start':
            newTime = this.updateTime(
              now - this.exerciseTime.before.startTime,
              this.timers.before.time,
              this.getTimerEvents(this.exerciseType.startTimer));

            color = (this.exerciseType.startTimer.maxTime && newTime > this.exerciseType.startTimer.maxTime) ?
              '#a80d0d' : '#00000';
            this.timers.before = {
              time: newTime,
              color: color,
            };
            break;

          case 'running': {
            const exerciseTime = now - this.exerciseTime.exercise.startTime - this.exerciseTime.totalFallTime;
            newTime = this.updateTime(exerciseTime,
              this.timers.exercise.time,
              this.getTimerEvents(this.exerciseType.exerciseTimer));
            color = (this.exerciseType.exerciseTimer.maxTime && newTime > this.exerciseType.exerciseTimer.maxTime) ?
              '#a80d0d' : '#00000';
            this.timers.exercise = {
              time: newTime,
              color: color,
            };
            break;
          }
          case 'wait':
          case 'ready':
          case 'finished':
            break;

          default:
            if (this.exerciseTime.currentFall) {
              const start = this.exerciseTime.currentFall.startTime ? this.exerciseTime.currentFall.startTime : now;
              const end = this.exerciseTime.currentFall.endTime ? this.exerciseTime.currentFall.endTime : now;
              newTime = this.updateTime(end - start,
                this.timers.currentFall ? this.timers.currentFall.time : 0,
                this.getTimerEvents(this.exerciseType.fallTimer));
              color = (this.exerciseType.fallTimer.maxTime && newTime > this.exerciseType.fallTimer.maxTime) ?
                '#a80d0d' : '#00000';
              this.timers.currentFall = {
                time: newTime,
                color: color,
              }
            }
        }
      }, 125);
    },
    beforeDestroy: function () {
      if (this.intervalTimer) {
        clearInterval(this.intervalTimer);
      }
    },
    methods: {
      copyTime: function() {
        const timer = find(this.exercise.timer, item => {
          return item.leg === this.activePass;
        });

        if (timer) {
          this.exerciseTime = cloneDeep(timer);

          if (this.exerciseTime.before.startTime && this.exerciseTime.before.endTime) {
            this.timers.before.time = this.updateTime(this.exerciseTime.before.endTime - this.exerciseTime.before.startTime, 0, null);
          }
          if (this.exerciseTime.exercise.startTime && this.exerciseTime.exercise.endTime) {
            let time = this.exerciseTime.exercise.endTime - this.exerciseTime.exercise.startTime;
            if (this.exerciseTime.totalFallTime) {
              time -= this.exerciseTime.totalFallTime;
            }
            this.timers.exercise.time = this.updateTime(time, 0, null);
          }
          this.timers.falls = map(this.exerciseTime.falls, fall => {
            const time = this.updateTime(fall.endTime - fall.startTime, 0, null);
            const color = (this.exerciseType.fallTimer.maxTime && time > this.exerciseType.fallTimer.maxTime) ?
              '#a80d0d' : '#00000';
            return {
              time: time,
              color: color,
            };
          });
          if (! this.exerciseTime.currentFall) {
            this.timers.currentFall = null;
          }
        }
      },
      getTimerEvents: function(timerSettings) {
        if (! timerSettings) {
          return null;
        }
        return timerSettings.events;
      },
      updateTime: function(time, currentTime, events) {
        const newTime = Math.floor(time / 1000);
        if (newTime !== currentTime && this.timer) {
          // check for actions to perform
          const event = find(events, event => {
            return event.time === newTime;
          });
          if (event) {
            // execute actions if any
            forEach(event.actions, action => {
              switch(action.actionType) {
                case 'beep':
                  this.beep.play();
                  break;
              }
            });
          }
        }
        return newTime;
      },
      formatTime: function(time) {
        const minutes = numeral(Math.floor(time / (60))).format('0');
        const seconds = numeral(Math.floor((time % 60))).format('00');

        return minutes + ':' + seconds;
      },
      buttonGreenLight: function() {
        if (this.exerciseTime.status !== 'wait') {
          return;
        }

        this.exerciseTime.before.startTime = this.ts.now();
        this.exerciseTime.status = 'start';
        this.saveTimeInformation();
      },
      buttonExerciseReady: function() {
        if (this.exerciseTime.status !== 'start') {
          return;
        }

        const now = this.ts.now();
        this.exerciseTime.before.endTime = now;
        this.exerciseTime.status = 'ready';
        this.saveTimeInformation();
      },
      buttonExerciseStart: function() {
        if (this.exerciseTime.status !== 'start' && this.exerciseTime.status !== 'ready') {
          return;
        }

        const now = this.ts.now();
        if (this.exerciseTime.status === 'start') {
          this.exerciseTime.before.endTime = now;
        }
        this.exerciseTime.exercise.startTime = now;
        this.exerciseTime.status = 'running';
        this.saveTimeInformation();
      },
      buttonExerciseFinish: function() {
        if (this.exerciseTime.status !== 'running') {
          return;
        }

        this.exerciseTime.exercise.endTime = this.ts.now();
        this.exerciseTime.status = 'finished';
        this.saveTimeInformation();
      },
      buttonFall: function() {
        if (this.exerciseTime.status !== 'running') {
          return;
        }

        const now = this.ts.now();
        this.exerciseTime.exercise.endTime = now;

        // create new fall:
        this.exerciseTime.currentFall ={
          fallStart: now,
          startTime: null,
          endTime: null,
          fallEnd: null,
        };
        this.timers.currentFall = '0:00';

        this.exerciseTime.status = 'fallWait';
        this.saveTimeInformation();
      },
      buttonFallTimeStart: function() {
        if (this.exerciseTime.status !== 'fallWait') {
          return;
        }

        const now = this.ts.now();
        this.exerciseTime.currentFall.startTime = now;
        this.exerciseTime.status = 'fall';
        this.saveTimeInformation();
      },
      fallTimeEnd: function() {
        const now = this.ts.now();
        this.exerciseTime.currentFall.endTime = now;
        this.exerciseTime.status = 'fallEnd';
      },
      buttonFallTimeEnd: function() {
        if (this.exerciseTime.status !== 'fall') {
          return;
        }

        this.fallTimeEnd();
        this.saveTimeInformation();
      },
      buttonFallRestart: function() {
        if (this.exerciseTime.status === 'fall') {
          this.fallTimeEnd();
        }
        if (this.exerciseTime.status !== 'fallEnd') {
          return;
        }

        const now = this.ts.now();
        this.exerciseTime.currentFall.fallEnd = now;
        this.exerciseTime.totalFallTime += now - this.exerciseTime.currentFall.fallStart;
        this.exerciseTime.exercise.endTime = null;
        this.exerciseTime.falls.push(this.exerciseTime.currentFall);
        this.exerciseTime.currentFall = null;
        this.timers.falls.push(this.timers.currentFall);
        this.timers.currentFall = null;
        this.exerciseTime.status = 'running';
        this.saveTimeInformation();
      },
      buttonReset: function() {
        this.$modal.show({
          title: this.$t('timer.reset'),
          message: this.$t('timer.reset.text'),
          onConfirm: () => {
            this.timers = {
              before: {
                time: 0,
                color: '#000000'
              },
              exercise: {
                time: 0,
                color: '#000000'
              },
              falls: [],
              currentFall: null,
            };
            this.exerciseTime = {
              status: 'wait',
              before: {
                startTime: null,
                endTime: null
              },
              exercise: {
                startTime: null,
                endTime: null,
              },
              falls: [],
              currentFall: null,
              totalFallTime: 0,
            };
            this.saveTimeInformation();
          }
        });
      },
      saveTimeInformation: function() {
        const opts = {
          timer: this.exerciseTime,
          pass: this.activePass,
        };
        this.$store.dispatch('exercise.setTimer', opts);
      },

      next: function () {
        console.log('next page');
        this.$store.dispatch("exercise.nextPage", {});
      },
      previous: function () {
        this.$store.dispatch("exercise.previousPage", {});
      },
    },
  }
</script>

<style scoped>
  .timers {
    margin-left: 140px;
  }
  .exerciseTimerGroup {
    width: 100%;
    clear: both;
  }

  h4 {
    clear: both;
    margin-top: 12px;
  }

  .timerButtons {
    float: right;
    width: 370px;
  }

  .timerButtons .c-button {
    margin-bottom: 20px;
    margin-left: 20px;
    width: 160px;
    height: 90px;
  }

  .exerciseTimer {
    font-size: 250%;
  }

  .c-actions {
    clear: both;
  }
</style>
