<template>
  <div>
    <template v-if="session">
      <div class="o-row o-row-tiny">
        <h3 class="c-title c-title--event u-push-1-of-5 u-3-of-5 u-padding-left-small">
          {{session.name}} - {{panel.name}} <span v-if="judge">- {{judge.lastName}} {{judge.firstname}}</span>
        </h3>
        <template v-if="passes.length === 1">
          <div class="o-layout u-margin-bottom-medium">
            <h4 class="c-title c-title--session o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small u-1-of-5-at-small">&nbsp;</h4>
            <div class="c-button--group o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small u-4-of-6-at-small">
              <div v-for="rotation in session.rotationsSet" class="c-button c-button--large c-button--primary"
                   :key="rotation.index"
                   :class="{'c-button--disabled': rotation.status !== 'active' || rotation.index >= rotations}"
                 v-on:click.prevent="selectRotation(rotation.index, passes[0])">
                {{$t('rotation')}} {{rotation.index+1}}
                <span class="c-button__extra-label">{{ $t('session.status.' + rotation.status) }}</span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="rotation in session.rotationsSet" :key="'rotation-'+rotation.index" class="o-layout u-margin-bottom-medium">
            <h4 class="c-title c-title--session o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small u-1-of-5-at-small">
              {{$t('rotation')}} {{rotation.index+1}}<br/>{{ $t('session.status.' + rotation.status) }}
            </h4>
            <div class="c-button--group o-layout__item u-push-none u-1-of-1 u-push-1-of-5-at-tiny u-4-of-5-at-tiny u-push-none-at-small u-4-of-6-at-small">
              <div v-for="(pass, pi) in passes" class="c-button c-button--large c-button--primary"
                   :key="rotation.index + '-' + pass.exerciseTypeId + '-' + pass.pass + '-' + pi"
                   :class="{'c-button--disabled': rotation.status !== 'active' || rotation.index >= rotations }"
                   v-on:click.prevent="selectRotation(rotation.index, pass)">
                {{$t('exercise.type.' + pass.exerciseTypeId)}}
                <span v-if="switchPanels" class="c-button__extra-label">
                  {{$t('block')}} {{pass.set}}
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import filter from 'lodash/filter'
  import find from 'lodash/find'

  import sessionLib from '@/lib/session'

  export default {
    name: "rotations",
    data: function() {
      return {
        judge: undefined,
        passes: [],
        switchPanels: false,
        rotations: 0,
      }
    },
    computed: {
      panel: function () {
        return find(this.$store.state.panels.items, item => {
          return item.id === this.$route.params.panelId;
        });
      },
      settings: function() {
        return find(this.$store.state.panels.configurations, item => {
          return item.panelId === this.$route.params.panelId;
        });
      },
      judges: function () {
        return filter(this.$store.state.members.items, item => { return item.judge });
      },
      session: function() {
        return find(this.$store.state.sessions.items, item => {
          return item.id === this.panel.sessionId;
        })
      }
    },
    created: function() {
      this.passes = this.settings.passes
      this.switchPanels = this.settings.switchPanels
      this.rotations = sessionLib.getSetRotations(this.session, this.panel.set)
    },
    mounted: function() {
      const member = find(this.panel.members, item => {
        return item.id === this.$route.params.panelMemberId;
      });
      if (member) {
        this.judge = find(this.judges, item => { return item.id === member.judgeId });
      }
    },
    methods: {
      selectRotation: function(rotation, pass) {
        this.$store.dispatch("panel.setActiveRotation", {
          panelId: this.panel.id,
          activeRotation: rotation,
          activeExerciseTypeId: pass.exerciseTypeId,
          activeSet: pass.set,
        });
      }
    }
  };
</script>

<style scoped>
</style>
