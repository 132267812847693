<template>
  <router-view />
</template>

<script>
  export default {
    name: 'block-wrapper',
  };
</script>

<style scoped>
</style>
