<template>
  <div class="c-button--options-pad">
    <template v-for="(option, i) in options">
      <div v-if="i && count > 10 && (i % 10 === 0) " :key="'break-' + i" class="c-button--options-pad--newline"></div>
      <button v-on:click.prevent="valuePressed(option.value)" :key="option.value"
              class="c-button c-button--square c-button--primary c-button--option"
              :class="{'c-button--disabled': option.disabled }">
        {{option.label}}
      </button>
    </template>
    <template v-if="valueType.skip">
      <div class="c-button--options-pad--newline"></div>
      <button v-on:click.prevent="valuePressed('-')" class="c-button c-button--primary">
        {{ $t('score.skipValue') }}
      </button>
    </template>
  </div>
</template>

<script>
  import includes from 'lodash/includes';

  export default {
    name: "select-numeric",
    props: ["valueType", "properties"],
    computed: {
      configuration: function () {
        return this.$store.state.exercise.exerciseConfiguration
      },
      max: function() {
        let max = this.valueType.max
        if (this.configuration[this.properties.group + '.' + this.properties.element + '.max']) {
          max = this.configuration[this.properties.group + '.' + this.properties.element + '.max']
        }
        return max
      },
      count: function() {
        return Math.max(this.max, 10)
      },
      options: function() {
        let elements = [];
        for (let i = 0; i <= this.count; i++) {
          let disabled = (i < this.valueType.min || i > this.max)
          if (this.valueType.values) {
            disabled = ! (includes(this.valueType.values, i))
          }
          elements.push({
            value: i,
            label: i,
            disabled: disabled
          })
        }
        return elements
      }
		},
    methods: {
      valuePressed: function(value) {
        this.$emit('value', {value: value});
        this.actionPressed('next');
      },
      actionPressed: function(action) {
        this.$emit('action', {action: action});
      }
    }
  };
</script>

<style scoped>
</style>
